import { Grid, Typography } from '@mui/material';
import './Success.css';

export default function Success() {
  return (
    <Grid direction="column" alignContent='center' sx={{ my: 8 }}>
      <div class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
      <Grid item>
        <Typography align='center' variant='h5'>Thank You!</Typography>
      </Grid>
    </Grid>
  );
}