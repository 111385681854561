import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, FormControl, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { red } from '@mui/material/colors';

export default function Form({
  onSubmit,
}) {
  const [names, setNames] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [rsvp, setRsvp] = React.useState('');
  const [pork, setPork] = React.useState(1);
  const [vege, setVege] = React.useState(0);
  const [transport, setTransport] = React.useState('');
  const [errors, setErrors] = React.useState({});

  const submit = React.useCallback(() => {
    console.log({ submit: true });
    
    const values = {
      names,
      email,
      rsvp,
      pork,
      vege,
      transport,
    };
    const localErrors = checkForm(values);

    setErrors(localErrors);
    if (Object.keys(localErrors).length === 0) {
      onSubmit(values);
    }
  }, [onSubmit, names, email, rsvp, pork, vege, transport]);

  React.useEffect(() => {
    const values = {
      names,
      email,
      rsvp,
      pork,
      vege,
      transport,
    };

    const recheck = checkForm(values);
    const errorCopy = { ...errors };
    
    Object.keys(errorCopy).forEach(key => {
      if (!(key in recheck)) {
        delete errorCopy[key];
      };
    });
    
    console.log({ errorCopy, errors });
    if (Object.keys(errorCopy).length !== Object.keys(errors).length) {
      setErrors(errorCopy);
    }
  }, [names, email, rsvp, pork, vege, transport, errors]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="name"
            name="names"
            value={names}
            onChange={e => setNames(e.target.value)}
            label="Guest name(s)"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            error={!!errors.names}
            helperText={<FormHelperText>
              {errors.names ? errors.names + ' · ' : ''}
              Please provide the name(s) of <strong>all guests</strong> coming to the wedding
            </FormHelperText>}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="Email address"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            error={!!errors.email}
            helperText={`${errors.email ? errors.email + ' · ' : ''}Used to send you a confirmation of your RSVP`}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl error={!!errors.rsvp}>
            <RadioGroup
              row
              name="rsvp"
              value={rsvp}
              onChange={e => setRsvp(e.target.value)}
              required
            >
              <FormControlLabel
                value="yes"
                control={<Radio sx={!!errors.rsvp ? { color: red[800] } : {}} />}
                label="Joyfully accepts"
                sx={!!errors.rsvp ? { color: red[800] } : {}}
              />
              <FormControlLabel
                value="no"
                control={<Radio sx={!!errors.rsvp ? { color: red[800] } : {}} />}
                label="Regretfully declines"
                sx={!!errors.rsvp ? { color: red[800] } : {}}
              />
            </RadioGroup>
            <FormHelperText>{`${errors.rsvp ? errors.rsvp + ' · ' : ''}the invitation`}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormLabel>Number of servings</FormLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="pork"
            name="pork"
            value={pork}
            onChange={e => setPork(e.target.value)}
            label="Pork/Chicken"
            fullWidth
            type='number'
            variant="standard"
            defaultValue={1}
            error={!!errors.pork}
            helperText={errors.pork}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="vege"
            name="vege"
            value={vege}
            onChange={e => setVege(e.target.value)}
            label="Vegetarian"
            fullWidth
            type='number'
            variant="standard"
            defaultValue={0}
            error={!!errors.vege}
            helperText={errors.vege}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl error={!!errors.transport}>
            <FormLabel>Will you require transportation the day of the wedding?</FormLabel>
            <RadioGroup
              row
              name="transport"
              value={transport}
              onChange={e => setTransport(e.target.value)}
              required
            >
              <FormControlLabel
                value="yes"
                control={<Radio sx={!!errors.transport ? { color: red[800] } : {}} />}
                sx={!!errors.transport ? { color: red[800] } : {}}
                label="Yes, please"
              />
              <FormControlLabel
                value="no"
                control={<Radio sx={!!errors.transport ? { color: red[800] } : {}} />}
                label="Not necessary"
                color="error"
                sx={!!errors.transport ? { color: red[800] } : {}}
              />
            </RadioGroup>
            <FormHelperText>{errors.transport}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid container row  xs={12} sm={12} justifyContent='center' p='24px'>
          <Button variant="contained" size='large'  onClick={submit}>Send</Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function checkForm({
  names,
  email,
  rsvp,
  pork,
  vege,
  transport,
}) {
  const errors = {};
  const isIntegerString = (str) => {
    const num = Number.parseFloat(str);
    return Number.isInteger(num);
  };

  if (!names) {
    errors.names = 'Required';
  }

  if (!email) {
    errors.email = 'Required';
  }

  if (!rsvp) {
    errors.rsvp = 'Required';
  }

  if (!isIntegerString(pork)) {
    errors.pork = 'Required · Set to 0 if you don\'t need any';
  }

  console.log({ vege, isInt: Number.isInteger(vege) });
  
  if (!isIntegerString(vege)) {
    errors.vege = 'Required · Set to 0 if you don\'t need any';
  }

  if (!transport) {
    errors.transport = 'Required';
  }

  return errors;
}
