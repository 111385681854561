import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import ProTip from './ProTip';
import Form from './Form';
import sendData from './api';
import Help from './Help';
import Success from './Success';
import Error from './Error';
import Loading from './Loading';

function App() {
  const [status, setStatus] = React.useState('pending');

  const onSubmit = React.useCallback(async (data) => {
    try {
      setStatus('loading');

      await Promise.all([
        sendData(data),
        new Promise((resolve) => setTimeout(resolve, 4000))
      ]);
      
      setStatus('success');
    } catch (e) {
      console.error(e);
      setStatus('error');
    }
  }, []);

  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Paper elevation={1} sx={{ px: 4, py: 2, borderRadius: 3 }}>
          <Box>
            <Grid xs display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h4" component="h1" gutterBottom>
                RSVP
              </Typography>
            </Grid>
            {status === 'pending' && <React.Fragment>
              <Form onSubmit={onSubmit} />
              <ProTip />
            </React.Fragment>}
            {status === 'loading' && <Loading />}
            {status === 'success' && <Success />}
            {status === 'error' && <Error />}
            <Help />
          </Box>
        </Paper>
      </Grid>
    </Container>
  );
}

export default App;
