import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { GlobalStyles, ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: '\'Alegreya Sans SC\', Arial',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          '#root': {
            width: '100vw',
            minHeight: '100vh',
            backgroundImage: 'url("https://wedding.hoa.ro/img/home-us-joy.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            backgroundBlendMode: 'darken',
          },
        }}
      />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
