// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-heart {
  display: inline-block;
  position: relative;
  width: 320px;
  height: 320px;
  transform: rotate(45deg);
  transform-origin: 160px 160px;
  margin: auto;
}

.lds-heart div {
  top: 128px;
  left: 128px;
  position: absolute;
  width: 128px;
  height: 128px;
  background: pink;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 128px;
  height: 128px;
  background: pink;
}

.lds-heart div:before {
  left: -96px;
  border-radius: 50% 0 0 50%;
}

.lds-heart div:after {
  top: -96px;
  border-radius: 50% 50% 0 0;
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }

  5% {
    transform: scale(1.1);
  }

  39% {
    transform: scale(0.85);
  }

  45% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(0.9);
  }
}`, "",{"version":3,"sources":["webpack://./src/Loading.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,wBAAwB;EACxB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,sEAAsE;AACxE;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,UAAU;EACV,0BAA0B;AAC5B;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".lds-heart {\n  display: inline-block;\n  position: relative;\n  width: 320px;\n  height: 320px;\n  transform: rotate(45deg);\n  transform-origin: 160px 160px;\n  margin: auto;\n}\n\n.lds-heart div {\n  top: 128px;\n  left: 128px;\n  position: absolute;\n  width: 128px;\n  height: 128px;\n  background: pink;\n  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);\n}\n\n.lds-heart div:after,\n.lds-heart div:before {\n  content: \" \";\n  position: absolute;\n  display: block;\n  width: 128px;\n  height: 128px;\n  background: pink;\n}\n\n.lds-heart div:before {\n  left: -96px;\n  border-radius: 50% 0 0 50%;\n}\n\n.lds-heart div:after {\n  top: -96px;\n  border-radius: 50% 50% 0 0;\n}\n\n@keyframes lds-heart {\n  0% {\n    transform: scale(0.95);\n  }\n\n  5% {\n    transform: scale(1.1);\n  }\n\n  39% {\n    transform: scale(0.85);\n  }\n\n  45% {\n    transform: scale(1);\n  }\n\n  60% {\n    transform: scale(0.95);\n  }\n\n  100% {\n    transform: scale(0.9);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
