import { Grid, Typography } from '@mui/material';
import './Success.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { red } from '@mui/material/colors';

export default function Error() {
  return (
    <Grid direction="column" alignContent='center' sx={{ my: 6, color: red[800] }}>
      <Grid item alignContent='center' align='center' sx={{ mb: 4}}>
        <CancelIcon sx={{ fontSize: 100 }} />
      </Grid>
      <Grid item>
        <Typography align='center' variant='h5'>An error occurred. Please try again.</Typography>
      </Grid>
    </Grid>
  );
}