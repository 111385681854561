import { Grid } from "@mui/material"

import './Loading.css';

export default function Loading() {
  return <Grid container sx={{ my: 2 }} alignContent='center' textAlign='center'>
    <div class="lds-heart">
      <div></div>
    </div>
  </Grid>;
}